<template>
  <div
    class="am-file"
    style="padding: 5px;"
  >
    <b-button
      v-ripple.400="'rgba(225,225,225,0.15)'"
      :variant="item.selected?'secondary':'flat-transparent'"
      :disabled="disabled"
      @dblclick="handleDbClick"
      @click="handleClick"
      @contextmenu.prevent.stop="handleRightClick"
    >
      <b-overlay
        :show="item.loading"
        variant="transparent"
        spinner-small
        rounded="sm"
        :opacity="0.2"
      >
        <VMenu placement="top-end">
          <div class="d-flex flex-column">
            <font-awesome-icon
              :icon="getFileIcon()"
              size="3x"
              :style="{ color: getFileColor() }"
            />
            <strong style="margin-top: 5px;width: 150px;">{{ item.name }}</strong>
          </div>
          <template #popper>
            <div
              class="d-flex flex-column"
              style="padding: 5px 10px"
            >
              <div
                v-if="item.type==='Folder'"
                class=""
              >
                <strong>Items: </strong> <span>{{ item.total_files }}</span>
              </div>
              <div class="">
                <strong>Size: </strong> <span>{{ item.size }}MB</span>
              </div>
            </div>
          </template>
        </VMenu>
      </b-overlay>
    </b-button>
    <vue-context
      ref="menuProp"
      :close-on-scroll="true"
    >
      <li
        v-for="data in menuItems"
        :key="data.icon"
      >
        <b-link
          class="d-flex align-items-center"
          @click="performAction(data.type)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BOverlay, VBTooltip, BLink,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import VueContext from 'vue-context'

const { skin } = useAppConfig()
export default {
  name: 'LenonFile',
  components: {
    BButton,
    BOverlay,
    VueContext,
    BLink,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      folderMenu: [
        { icon: 'UploadCloudIcon', text: 'Upload Files', type: 'upload' },
        { icon: 'EditIcon', text: 'Edit Folder', type: 'edit-folder' },
        // { icon: 'EyeIcon', text: 'View Details', type: 'view-folder' },
        { icon: 'TrashIcon', text: 'Delete Folder', type: 'delete' },
      ],
      fileMenu: [
        { icon: 'Share2Icon', text: 'Share File', type: 'share' },
        { icon: 'EditIcon', text: 'Edit File', type: 'edit-file' },
        // { icon: 'EyeIcon', text: 'View Details', type: 'view-file' },
        { icon: 'TrashIcon', text: 'Delete File', type: 'delete' },
      ],
      selected: false,
    }
  },
  computed: {
    darkMode() {
      return skin.value === 'dark'
    },
    menuItems() {
      return this.item.type === 'Folder' ? this.folderMenu : this.fileMenu
    },
  },
  methods: {
    performAction(type) {
      this.$emit('optionSelected', type)
    },
    handleDbClick() {
      this.$emit('dbclick')
    },
    handleClick(e) {
      this.$emit('click', e)
    },
    handleRightClick(e) {
      this.$refs.menuProp.open()
      this.$emit('rightclick', e)
    },
    getFileColor() {
      if (this.item.type === 'Docx') {
        return '#3a8bfc'
      }
      if (this.item.type === 'Ppt') {
        return '#d96207'
      }
      if (this.item.type === 'Excel') {
        return '#017d20'
      }
      if (this.item.type === 'Pdf') {
        return '#f50c0c'
      }
      if (this.item.type === 'Folder') {
        return '#ffda6c'
      }
      return null
    },
    getFileIcon() {
      if (this.item.type === 'Docx') {
        return 'file-word'
      }
      if (this.item.type === 'Ppt') {
        return 'file-powerpoint'
      }
      if (this.item.type === 'Excel') {
        return 'file-excel'
      }
      if (this.item.type === 'Pdf') {
        return 'file-pdf'
      }
      if (this.item.type === 'Folder') {
        return 'folder'
      }
      return null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
</style>
