<template>
  <!--    Book Form-->
  <validation-observer
      ref="bookForm"
      v-slot="{invalid}"
  >
    <lenon-modal
        :title="`${updateBookMode?'Update':'Create'} Book`"
        :show="bookModalOpened"
        :show-overlay="deletingBook"
        size="md"
        @onClose="closeBookModal()"
    >
      <error-display :error="error"/>
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
              v-model="selectedBookId"
              :options="books"
              label-name="title"
              value-name="id"
              placeholder="All Books"
              @input="populateBooksForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
              icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteBook()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @onClick="resetBookForm()"
          />
        </div>
      </b-row>
      <p class="text-danger">Please note that uploading books may take sometime depending on the size of the document, so be patient.</p>
      <lenon-input
          v-model="book.title"
          name="title"
          placeholder="Enter the correct title of the book with author name."
          rules="required"
          label="Title"
      />
      <lenon-select
          v-if="!updateBookMode"
          v-model="book.doc_type"
          name="doc_type"
          placeholder="Select Document Type"
          rules="required"
          label="Document Type"
          :options="documentTypes"
      />
      <div class="d-flex align-items-center mb-1" v-if="accept && !updateBookMode">
        <lenon-file-button
            label="Upload Books"
            variant="outline-primary"
            class="mr-1"
            :accept="accept"
            @onFileUpload="setUploadedFile"
        />
        <b-badge
            v-if="book.document"
            variant="light-success"
        >
          <feather-icon icon="CheckIcon"/>
          One file uploaded
        </b-badge>
      </div>
      <b-form-checkbox
          v-model="allUsers"
          class="mb-1"
      >
        Accessible to all users
      </b-form-checkbox>
      <lenon-multi-select
          v-model="book.users"
          :options="teachers"
          name="users"
          label-name="fullName"
          value-name="id"
          placeholder="Select Users"
          rules="required"
          label="Users Accessible to"
      />
      <div style="margin-bottom: 70px !important;"/>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeBookModal()"
          />
          <lenon-button
              :label="updateBookMode?'Update':'Create'"
              :disabled="invalid"
              :loading="bookLoading"
              loading-text="Loading..."
              @onClick="updateBookMode?updateBook():createBook()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect'
import LenonFileButton from '@/lenon/components/LenonFileButton'
import LenonFile from '@/views/documents/folders/File'

export default {
  name: 'BooksSetup',
  components: {
    LenonFile,
    LenonFileButton,
    LenonMultiSelect,
    LenonTextArea,
    ErrorDisplay,
    LenonDatePicker,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
    BBadge,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      deletingBook: false,
      bookModalOpened: false,
      updateBookMode: false,
      bookLoading: false,
      allUsers: false,
      selectedBookId: null,
      book: {
        id: null,
        title: null,
        document: null,
        doc_type: null,
        users: [],
      },
      accept: null,
      documentTypes: [{
        label: 'Pdf Document',
        value: 'pdf'
      },
        {
          label: 'Word Document',
          value: 'doc'
        },
        // {
        //   label: 'Powerpoint Document',
        //   value: 'ppt'
        // }, {
        //   label: 'Excel Document',
        //   value: 'exl'
        // }
        ]
    }
  },
  computed: {
    docType() {
      return this.book.doc_type
    },
    books() {
      return this.$store.getters['documents/books']
    },
    teachers() {
      return this.$store.getters['termsClasses/teachers']
    },
    selectedBook() {
      return this
          .books.find(g => +g.id === +this.selectedBookId)
    },
  },
  watch: {
    docType(val) {
      let types = null
      if (val === 'doc') {
        types = '.docx,.doc'
      }
      if (val === 'ppt') {
        types = '.pptx,.ppt'
      }
      if (val === 'pdf') {
        types = '.pdf'
      }
      if (val === 'exl') {
        types = '.xlsx,.xls'
      }
      this.accept = types
    },
    allUsers(val) {
      if (val) {
        this.book.users = this.teachers
      } else {
        this.book.users = []
      }
    },
    modalOpened(opened) {
      this.bookModalOpened = opened
    },
    selectedBookId(id) {
      if (!id) {
        this.resetBookForm()
      }
    },
    id(id) {
      if (id) {
        this.selectedBookId = id
      }
    },
  },
  methods: {
    setUploadedFile(e) {
      this.book.document = e.file
      this.book.title = e.file.name
    },
    resetBookForm() {
      this.updateBookMode = false
      this.allUsers = false
      this.book = {
        id: null,
        title: null,
        users: [],
      }
      this.selectedBookId = null
      this.$refs.bookForm.reset()
    },
    populateBooksForm(ef) {
      if (this.selectedBook) {
        this.updateBookMode = true
        this.book = {
          ...this.selectedBook,
        }
        this.book.users = this.book.users.map(uid => this.teachers.find(u => +u.id === +uid))
      } else {
        this.resetBookForm()
      }
    },
    closeBookModal() {
      this.bookModalOpened = false
      this.$emit('modalClosed')
    },
    createBook() {
      this.error = {}
      this.bookLoading = true
      const fd = new FormData()
      fd.append('id', this.book.id)
      fd.append('title', this.book.title)
      fd.append('type', this.book.doc_type)
      fd.append('document', this.book.document)
      for (let i = 0; i < this.book.users.length; i++) {
        fd.append('users[]', +this.book.users[i].id)
      }
      this.$http.post('/documents/books', fd)
          .then(res => {
            this.showSuccess('Uploaded book successfully')
            this.$store.commit('documents/addBook', res.data)
            this.resetBookForm()
          })
          .catch(err => {
            this.error = err
            logData(err)
            this.showError('Failed to upload book')
          })
          .finally(() => {
            this.bookLoading = false
          })
    },
    updateBook() {
      this.error = {}
      this.bookLoading = true
      const users = this.book.users.map((u)=>u.id)
      this.$http.put(`/documents/books/${this.book.id}`, {
        ...this
        .book,
        users,
      })
          .then(res => {
            this.showSuccess('Updated book successfully')
            this.$store.commit('documents/updateBook', res.data)
            this.resetBookForm()
          })
          .catch(err => {
            this.error = err
            logData(err)
            this.showError('Failed to update book')
          })
          .finally(() => {
            this.bookLoading = false
          })
    },
    deleteBook() {
      if (!this.selectedBookId) {
        this.showInfo('Please select a book ')
        return
      }
      this.deletingBook = true
      this.$http.delete(`/documents/books/${this.selectedBookId}`)
          .then(() => {
            this.showSuccess('Deleted book successfully')
            this.$store.commit('documents/removeBook', this.selectedBookId)
            this.selectedBookId = null
            this.resetBookForm()
          })
          .catch(err => {
            logData(err)
            this.showError('Failed to delete book')
          })
          .finally(() => {
            this.deletingBook = false
          })
    },
  },
}
</script>
